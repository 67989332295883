.App{
    width: 100vw;
    height: 100vh;

    // Header

    header{
        background-color: var(--black-200);
        width: 100%;
        height: 10%;
        position: fixed;
        
        color: var(--white);
        
        display: flex;
        justify-content: center;
        align-items: center;


        .logo{
            display: flex;
            justify-content: center;
            align-items: center;

            a{
                font-family: 'Satisfy', cursive;
                font-size: 2em;
            }

            svg{
                font-size: 1.25em;
                margin-left: 0.5em;
                transition: all 0.2s;

                &:hover{
                    color: var(--blue)
                }
            }
        }

        .menuNav{
            display: none;
        }
    }

    //Estilização geral
    .about, 
    .skills, 
    .projects, 
    .contact,
    footer
    {
        h1{
            color: var(--white);
            text-align: center;
            font-weight: bold;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3em;
    }

    main{

        //Sobre

        .about{
            background: var(--black-100);

            h1{
                margin-top: 2em;
                margin-bottom: 1em;
            }

            .information{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: var(--white);
                padding: 0.5em;
                width: 100%;

                img{
                    width: 200px;
                    height: 200px;
                    margin-bottom: 1em;
                    border-radius: 50%;
                    border: 2px solid var(--dark-blue)
                }

                p{
                    font-family: 'Georama', sans-serif;
                    text-align: center;
                    font-size: 1.15em;
                }

                .aboutTitle{
                    font-weight: bold;
                    animation: typing 6s 1s normal steps(120);
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;
                }

                .aboutText{
                    margin-top: 1em;
                }

                @keyframes typing {
                    from{
                        width: 0;
                    }

                    to{
                        width: 100%;
                    }
                }

            }

            .school{
                display: flex;
                justify-content: center;
                align-items: center;

                margin-top: 1em;
                color: var(--white);
                padding: 0.75em;
                border-radius: 5px;
                background: var(--black-200);

                div{
                    width: 100%;

                    h2{
                        font-size: 1.25em;
                        font-weight: bold;
                        font-family: 'Georama', sans-serif;
                        width: 100%;
                    }
                }

                svg{
                    font-size: 4em;
                    margin-right: 0.25em;
                    color: var(--blue);
                }

            }
        }

        //Habilidades

        .skills{
            background-color: var(--dark-blue);

            .skillsLogo{
                display: flex;
                flex-direction: column;
                margin-top: 0.1em;

                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    &:hover{
                        svg{
                            color: var(--black-200);
                            transform: scale(1.25);
                        }

                        p{
                            color: var(--black-200);
                        }
                    }

                    svg{
                        margin-top: 0.3em;
                        color: var(--white);
                        font-size: 6em;
                        transition: all 0.2s;
                    }

                    p{
                        font-weight: bold;
                        color: var(--white);
                        letter-spacing: 3px;
                        transition: all 0.2s;
                    }
                }

            }
        }

        //Projetos

        .projects{
            background-color: var(--black-200);

            a{
                background: var(--dark-blue);
                color: var(--white);
                padding: 0.5em;
                border-radius: 5px;
                margin-top: 0.5em;
                transition: all 0.2s;

                &:hover{
                    background: var(--blue);
                    font-weight: bold;
                }
            }

            .cardProjects{
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 1em;
                    padding: 0.5em;
                    border: 1px solid var(--dark-blue);
                    border-radius: 5px;

                    h1{
                        font-size: 1em;
                        color: var(--dark-blue);
                        font-weight: bold;
                        letter-spacing: 2px;
                    }

                    p{
                        color: var(--white)
                    }

                    a{
                        background: var(--dark-blue);
                        padding: 0.2em;
                        border-radius: 5px;
                    }
                }
            }
        }

        //Contato

        .contact{
            background-color: var(--dark-blue);

            h1{
                margin-bottom: 0.5em;
            }

            div{
                margin-bottom: 0.45em;
                div{
                    color: var(--white);
                    display: flex;
                    align-items: center;
                    font-size: 1.1em;
                    
                    a{
                        margin-left: 0.1em;
                    }
                }
            }
        }
    }

    //Rodapé

    footer{
        background-color: var(--black-200);
        color: var(--white);
        padding: 0.5em;
        height: 10%;

        div{
            margin-top: 0.2em;
            
            a{
                margin-right: 3px;
                font-size: 1.10em;

                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

@media(min-width: 768px){
    .App{

        //Cabeçalho

        header{

            justify-content: space-around;

            .logo{
                a{
                    font-size: 3em;
                    transition: all 0.2s;

                    &:hover{
                        color: var(--blue);
                    }
                }

                svg{
                    display: none;
                }
            }

            .menuNav{
                display: flex;
                justify-content: center;
                align-items: center;

                ul{
                    display: flex;

                    li{
                        font-size: sans-serif;
                        margin-right: 0.5em;
                        font-size: 1.45em;

                        a{
                            transition: all 0.2s;
                            &:hover{
                                letter-spacing: 1.75px;
                                color: var(--blue);
                            }
                        }
                    }
                }
            }
        }

        main{

            //Sobre

            .about{
                .information{
                    .aboutTitle{
                        white-space: nowrap;
                    }
                }  
            }

            //Habilidades

            .skills{
                flex-direction: row;

                h1{
                    margin-right: 5px;
                }

                .skillsLogo{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);

                    div{
                        padding: 1em;

                        svg{
                            margin-top: 0;
                        }
                    }
                }

            }

            //Projetos

            .projects{
                .cardProjects{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);

                    div{
                        margin-left: 1em;
                    }
                }
            }

            //Contato

            .contact{
                div{
                    font-size: 1.25em;
                }
            }
        }

        //Rodapé

        footer{

            span{
                font-size: 1.2em;
            }

            div{
                a{
                    font-size: 1.5em;
                }
            }
        }
    }
}