.modal{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;

    display: flex;
    justify-content: center;
    align-items: center;

    .menuNav{
        background-color: rgba(59, 63, 64, 0.8);
        padding: 0.7em;
        width: 60%;
        height: 60%;
        border-radius: 5px;

        display: flex;
        justify-content: center;
        align-items: center;
        
        ul{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            li{
                font-size: 1.25em;
                text-transform: uppercase;
                letter-spacing: 3px;
                font-family: sans-serif;
                padding: 1em;
                transition: all 0.2s;

                &:first-child{
                    padding-top: 0;
                    margin-top: 0;
                }

                &:hover{
                    color: var(--blue);
                }
            }
        }
    }
}